import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=6408fe52&scoped=true&"
import script from "./Loading.vue?vue&type=script&lang=js&"
export * from "./Loading.vue?vue&type=script&lang=js&"
import style0 from "./Loading.vue?vue&type=style&index=0&id=6408fe52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!C:\\Users\\Tecplads\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6408fe52",
  null
  
)

export default component.exports